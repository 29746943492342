import React, { useState } from 'react';
import api, { getCsrfToken } from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

function Login({ onLoginSuccess }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();
        getCsrfToken()
            .then(() => {
                return api.post('/login', { email, password });
            })
            .then((response) => {
                onLoginSuccess(response.data);
            })
            .catch(() => {
                setErrorMessage('ログインに失敗しました');
            });
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold text-center mb-4">ログイン</h2>
                <form onSubmit={handleLogin}>
                    <div className="mb-4">
                        <label className="block text-gray-700">メールアドレス</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded mt-2"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">パスワード</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded mt-2"
                            required
                        />
                    </div>
                    {errorMessage && (
                        <div className="text-red-500 text-sm mb-4 flex items-center">
                            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                            {errorMessage}
                        </div>
                    )}
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 rounded mt-4 hover:bg-blue-600"
                    >
                        ログイン
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Login;
