import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

// Laravel API用のAxiosインスタンス
const api = axios.create({
    // Laravel APIのエンドポイント
    baseURL: process.env.REACT_APP_API_BASE_URL + '/api',
});

// Sanctum用のAxiosインスタンス（CSRFトークン取得専用）
const csrfApi = axios.create({
    // /apiが不要なエンドポイント
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const getCsrfToken = () => {
    // CSRFトークンを取得
    return csrfApi.get('/sanctum/csrf-cookie');
};

export default api;
