import React from 'react';
import api from '../api';


function AdminDashboard({ user, onLogout }) {
    const handleLogout = async () => {
        try {
            await api.post('/logout');  // ログアウトAPIを呼び出す
            onLogout();  // ログアウト後に親コンポーネントに通知
        } catch (error) {
            console.error('ログアウトに失敗しました', error);
        }
    };
    return (
        <div className="min-h-screen bg-gray-100">
            <header className="bg-blue-600 text-white py-4 px-6">
                <h1 className="text-xl font-bold">管理者ダッシュボード</h1>
                <button
                    onClick={handleLogout}
                    className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
                >
                    ログアウト
                </button>
            </header>
            <main className="p-6">
                <h2 className="text-2xl mb-4">ようこそ、管理者 {user.name}さん</h2>
                <div>
                    <h3 className="text-xl font-bold mb-2">ユーザー管理</h3>
                    <button className="bg-green-500 text-white py-2 px-4 rounded mr-2 hover:bg-green-600">ユーザー一覧</button>
                    <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">新規ユーザー追加</button>
                </div>
            </main>
        </div>
    );
}

export default AdminDashboard;
