import React from 'react';
import api from '../api';

function Dashboard({ user, onLogout }) {
    const handleLogout = async () => {
        try {
            await api.post('/logout');  // ログアウトAPIを呼び出す
            onLogout();  // ログアウト後に親コンポーネントに通知
        } catch (error) {
            console.error('ログアウトに失敗しました', error);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <header className="bg-blue-600 text-white py-4 px-6">
                <h1 className="text-xl font-bold">ダッシュボード</h1>
                <button
                    onClick={handleLogout}
                    className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
                >
                    ログアウト
                </button>
            </header>
            <main className="p-6">
                <h2 className="text-2xl mb-4">こんにちは、{user.name}さん</h2>
                <p>ダッシュボードへようこそ！</p>
            </main>
        </div>
    );
}

export default Dashboard;
